import router from 'tools/router';
import dialog from 'tools/dialog';
import datatables from 'tools/datatables';

import './airport.scss';

export function loadAirports() {
  $('#app-airport-load').on('click', function () {
    const title = $(this).text();
    dialog.loading(title);

    $.post(
      //URL
      router.generate('app_airport_loadFromProvider_AJAX'),

      // Success
      function (newAirports) {
        dialog.open(title, newAirports, 700, true);
        const options = {
          searching: false,
          paging: false,
          info: false,
          columnDefs: [{ orderable: false, targets: '_all' }],
          order: [
            [1, 'asc'],
            [2, 'asc'],
          ],
        };
        datatables.display('list', dialog.DIALOG_ID, options);
      }
    );
  });
}
