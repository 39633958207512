import 'bootstrap';
import './css/bootstrap.less';

import './css/layout.scss';
import './css/tooltip.scss';

export function base() {
  $(function () {
    initialize();
  });
}

function initialize() {
  // Bootstrap ToolTips
  $('[data-toggle="tooltip"]').tooltip();
}
