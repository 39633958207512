import display from './js/display.js';
import { manageRiskyImages } from './js/images.js';
import { loadMaps, loadElevations } from './js/maps.js';
import printConfiguration from './js/print-configuration.js';
import {
  organiseFrontPageParticipants,
  organiseVoucherParticipants,
} from './js/participants.js';
import { resizeImages, resizeTitles } from './js/resize.js';
import { splitSheets } from './js/split-sheets.js';

import 'paper-css/paper.css';
import './css/print.scss';

export function print() {
  if ($('body#app-print-program').length > 0) {
    const isReady = getIsReady();
    isReady.addToLoadingList('images');

    $(function () {
      loadMaps(isReady);
      loadElevations();
      //Manage images which are at risk of not being loaded
      manageRiskyImages();
    });

    // Wait for the whole page to be loaded (especialy images) before making treatements
    $(window).on('load', function () {
      isReady.loaded('images');
      // Document modifications
      printConfiguration.removeEmptySelectableElements();
      resizeImages();
      resizeTitles();
      display.notebookDisplay();
      if ($('.program-print-voucher').length > 0) {
        organiseVoucherParticipants();
      }

      // Must come after the other modifications
      const nodeActions = {};
      if ($('.program-print-frontPage').length > 0) {
        nodeActions['.program-print-participants-title:eq(0)'] =
          organiseFrontPageParticipants;
      }
      splitSheets(nodeActions);
      display.addPagination();

      // Display the document
      printConfiguration.addClickEvents();
      display.printButton();
      display.displayDocument();

      // Scroll, done at the end otherwise the anchor messes with the splitting process
      printConfiguration.scrollToClickedElement();
    });
  }
}

function getIsReady() {
  const loadingList = {};

  return {
    addToLoadingList: (element) => {
      loadingList[element] = true;
      hidePrintButton();
    },
    loaded: (element) => {
      loadingList[element] = false;

      const nbLoading = Object.keys(loadingList).filter(
        (element) => loadingList[element]
      ).length;
      if (nbLoading === 0) {
        displayPrintButton();
      }
    },
  };
}

function displayPrintButton() {
  $('.program-print-print-loading').addClass(
    'program-print-print-loading-hidden'
  );
}

function hidePrintButton() {
  $('.program-print-print-loading').removeClass(
    'program-print-print-loading-hidden'
  );
}
