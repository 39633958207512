import { loadAirports } from './airport/airport.js';
import { cityFields } from './city/city-fields.js';
import { dayActivities } from './day/day-activities.js';
import { images } from './images/images.js';
import { program } from './program/program.js';
import { print } from './print/print.js';
import { tracking } from './tracking/tracking.js';

import './service/service.scss';

export function tripplanner() {
  loadAirports();
  cityFields();
  dayActivities();
  images();
  program();
  print();
  tracking();
}
